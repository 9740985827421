import { Component, Input } from '@angular/core';
import { DateFormats } from '@core/configs/app-date.config';
import { DetailsTile } from '@core/models/details-tile.model';
import { DetailsTileDataType, LinkableType } from '@core/enums/details-tile-data.enum';

@Component({
  selector: 'app-details-tile',
  templateUrl: './details-tile.component.html',
  styleUrls: ['details-tile.component.scss'],
})
export class DetailsTileComponent {
  @Input() public title: string;
  @Input() public details: DetailsTile[];
  @Input() public profilePhoto: string;

  public DateFormats: typeof DateFormats = DateFormats;
  public DetailsTileDataType: typeof DetailsTileDataType = DetailsTileDataType;
  public LinkableType: typeof LinkableType = LinkableType;
}
