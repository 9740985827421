import { Pipe, PipeTransform } from '@angular/core';
import { ContractStatus } from '@features/client/contract/enums/contract-statuses.enum';
import { ContractStatusIcon } from '@features/client/contract/enums/contract-status-icon.enum';

@Pipe({
  name: 'contractStatusIcon',
})
export class ContractStatusIconPipe implements PipeTransform {
  public transform(value: string): string {
    return ContractStatusIcon[Object.keys(ContractStatus)
      .find(orderStatusEnumKey => ContractStatus[orderStatusEnumKey] === value)];
  }
}
