import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LoginConfigService } from '@capturum/login';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { TenantSettings } from '@core/models/tenant-settings.model';
import { FaviconService } from '@core/services/favicon.service';
import { SetTenantKey, SetTenantSettings } from '@core/store/tenant.actions';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { TenantKeys } from '@shared/enums/tenant-keys.enum';
import { SettingService } from '@shared/services/setting.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TenantResolver implements Resolve<boolean> {
  public settings: TenantSettings;

  constructor(
    private readonly store: Store,
    private readonly apiService: SettingService,
    private readonly themeService: ThemeService,
    private readonly loginConfigService: LoginConfigService,
    private readonly faviconService: FaviconService,
    private readonly titleService: Title,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const tenantKey = this.getTenantKey();

    return this.store.dispatch(new SetTenantKey(tenantKey)).pipe(
      tap(() => {
        this.setTenantSettings(tenantKey);
      }),
    );
  }

  private setTenantSettings(tenantKey: string): void {
    this.titleService.setTitle(tenantKey.charAt(0).toUpperCase() + tenantKey.slice(1));
    this.faviconService.setIcon(tenantKey);

    this.apiService.getSettingsByTenantKey(tenantKey).subscribe((settings: TenantSettings) => {
      this.settings = settings?.data;

      this.store.dispatch(new SetTenantSettings(this.settings));
      this.setTheme();

      if (this.settings) {
        this.setLoginConfig();
      }
    });
  }

  private getTenantKey(): string {
    const defaultTenant = TenantKeys.adamas;
    const hostName: string[] = window.location.host.split('.');

    return (hostName[0]?.includes('localhost'))
      ? defaultTenant
      : hostName[environment.tenantKeyHostIndex];
  }

  private setTheme(): void {
    if (this.settings) {
      this.themeService.setProps({
        [CSSVarNames.Font]: 'OpenSans sans-serif regular',
        [CSSVarNames.Primary]: this.settings['general.tenant_primary_color'],
        '--cap-secondary-color': this.settings['general.tenant_secondary_color'],
        [CSSVarNames.PrimaryShadow]: 'rgba(245, 116, 20, 0.15)',
        [CSSVarNames.Error]: '#e02020',
        [CSSVarNames.PrimaryShadow]: this.settings['general.tenant_primary_color'],
        [CSSVarNames.SecondaryShadow]: this.settings['general.tenant_secondary_color'],
      });
    }
  }

  /**
   * Update loginConfig with tenant settings. Note: All tenant settings start
   * with 'qube' as a prefix, regardless of the current tenant.
   */
  private setLoginConfig(): void {
    this.loginConfigService.setConfig({
      backgroundImageUrl: this.settings['qube.tenant_bg'],
      logoImageUrl: this.settings['qube.tenant_login_logo'],
    });
  }
}
