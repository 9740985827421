<form [formGroup]="inputsForm"
      class="inputs-code w-100">
  <ng-container *ngFor="let item of inputsArray?.controls; let i = index"
                formArrayName="myCode">
    <cap-input maxLength="1"
               pKeyFilter="pint"
               styleClass="input-code"
               [formControl]="item"
               (onInput)="focusOnNextInput(i, $event.target.value)">
    </cap-input>
  </ng-container>

  <cap-button icon="fas fa-backspace"
              styleClass="clear-button"
              (onClick)="clearValue()">
  </cap-button>
</form>
