import { Component, OnInit } from '@angular/core';
import { AuthService } from '@capturum/auth';
import { DestroyBase } from '@capturum/shared';
import { AppLocale } from '@core/configs/app-locale.config';
import { ClientRoutes } from '@core/enums/routes.enum';
import { MenuItem } from '@core/models/menu-item.model';
import { LoadingService } from '@core/services/loading.service';
import { ResponsiveScreenService } from '@core/services/responsive-screen.service';
import { DocumentApiService } from '@features/client/document/services/document-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { SetUnopenedCounters } from '@shared/store/general.actions';
import { interval } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
})
export class BasicLayoutComponent extends DestroyBase implements OnInit {
  public menuItems: MenuItem[] = [];
  public isSmallScreenView: boolean;

  constructor(
    public loadingService: LoadingService,
    public readonly documentApiService: DocumentApiService,
    private readonly responsiveScreenService: ResponsiveScreenService,
    private translateService: TranslateService,
    private readonly actions: Actions,
    private readonly authService: AuthService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setMenuItems();
    this.setTranslationsDependencies();
    this.setUnopenedDocuments();

    this.responsiveScreenService.smallScreenView$.pipe(takeUntil(this.destroy$)).subscribe((isSmallScreenView) => {
      this.isSmallScreenView = isSmallScreenView;
    });

    this.actions.pipe(ofActionSuccessful(SetUnopenedCounters))
      .subscribe(state =>
        this.menuItems[2].unopenedCount = state.unopenedCounter,
      );
  }

  public setMenuItems(): void {
    this.menuItems = [
      {
        title: 'qube.header.dashboard.title',
        icon: 'columns',
        routerLink: ClientRoutes.clientDashboard,
        permissions: ['project.show'],
      },
      {
        title: 'qube.header.projects.title',
        icon: 'copy',
        routerLink: ClientRoutes.clientProjects,
        permissions: ['project.manage', 'project.manage.tenant'],
      },
      {
        title: 'qube.header.project.title',
        icon: 'file-chart-line',
        routerLink: ClientRoutes.clientProject,
        permissions: ['project.show'],
      },
      {
        title: 'qube.header.document.title',
        icon: 'folder',
        routerLink: ClientRoutes.clientDocuments,
        unopenedCount: 0,
        permissions: ['document.manage', 'document.show.dashboard'],
      },
      {
        title: 'qube.header.planning.title',
        icon: 'calendar-alt',
        routerLink: ClientRoutes.clientPlanning,
        permissions: ['meeting.manage'],
      },
      {
        title: 'qube.header.contract.title',
        icon: 'file-signature',
        routerLink: ClientRoutes.clientContracts,
      },
    ];
  }

  private setUnopenedDocuments(): void {
    interval(5000).pipe(
      switchMap(() => this.documentApiService.getUnopenedDocuments({}).pipe(
        map((response) => response?.data?.length),
      )),
      takeUntil(this.destroy$),
    ).subscribe((unopenedCount) => {
      this.menuItems[2].unopenedCount = unopenedCount;
    });
  }

  private setTranslationsDependencies(): void {
    const language = this.authService.getUser()?.locale?.code;

    this.translateService.setDefaultLang(AppLocale.callbackLocale);

    this.translateService.addLangs(AppLocale.availableLocales);
    this.translateService.use(language || this.translateService.defaultLang);
  }
}
