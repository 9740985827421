import { Injectable } from '@angular/core';
import { TenantStatePrototype } from '@core/store/tenant-state-prototype.model';
import { SetTenantKey, SetTenantSetting, SetTenantSettings } from '@core/store/tenant.actions';
import { Action, State, StateContext } from '@ngxs/store';

@State<TenantStatePrototype>({
  name: 'tenant'
})
@Injectable()
export class TenantState {
  @Action(SetTenantKey)
  public setTenantKey(ctx: StateContext<TenantStatePrototype>, { key }: SetTenantKey): void {
    const state = ctx.getState();

    ctx.setState({ ...state, key });
  }

  @Action(SetTenantSettings)
  public setTenantSettings(ctx: StateContext<TenantStatePrototype>, { settings }: SetTenantSettings): void {
    const state = ctx.getState();

    ctx.setState({ ...state, settings });
  }

  @Action(SetTenantSetting)
  public setTenantSetting(ctx: StateContext<TenantStatePrototype>, { setting }: SetTenantSetting): void {
    const state = ctx.getState();

    ctx.setState({ ...state, settings: { ...state.settings, ...setting } });
  }
}
