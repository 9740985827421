<div class="title">
  <h2>{{ title }}</h2>
</div>

<div class="body">
  <div class="row overflow-hidden">
    <div class="col-12" [class.col-lg-9]="profilePhoto">
      <div *ngFor="let detail of details; let i = index" class="row" [class.mb-4]="profilePhoto || i < details.length - 1">
        <div class="col-6">
          <span class="label">{{ detail?.label }}</span>
        </div>

        <div [ngSwitch]="detail.type" class="col-6">
          <span *ngSwitchCase="DetailsTileDataType.date"
                class="text-field">{{ detail?.value | date: DateFormats.displayFormat }}
          </span>

          <span *ngSwitchCase="DetailsTileDataType.base"
                class="text-field">{{ detail?.value | baseDataTranslation }}
          </span>

          <span *ngSwitchCase="DetailsTileDataType.status" [ngClass]="detail?.value" class="text-field">
             <i [ngClass]="detail?.value | contractStatusIcon" class="mr-2"></i>
            <span>{{ detail?.baseDataValueId | baseDataTranslation }}</span>
          </span>

          <app-hyperlink *ngSwitchCase="LinkableType.email"
                        [type]="LinkableType.email"
                        [uri]="detail?.value">
          </app-hyperlink>

          <app-hyperlink *ngSwitchCase="LinkableType.phone"
                         [type]="LinkableType.phone"
                         [uri]="detail?.value">
          </app-hyperlink>

          <span *ngSwitchDefault class="text-field">{{ detail?.value }}</span>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-3" *ngIf="profilePhoto">
      <div class="logo-wrapper">
        <img class="logo" [src]="profilePhoto">
      </div>
    </div>
  </div>
</div>
