import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navigation-card',
  templateUrl: './navigation-card.component.html',
  styleUrls: ['./navigation-card.component.scss'],
})
export class NavigationCardComponent {
  @Input() public icon: string;
  @Input() public buttonLabel: string;
  @Input() public route: string | string[];
  @Input() public buttonClass: string;
  @Output() public outputEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
}
