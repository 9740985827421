<div class="tile">
  <div class="title">
    <h2>{{ 'qube.header.project.title' | translate }}</h2>
  </div>

  <div class="body">
    <p class="mb-4 info-text">{{ description | translate }}</p>

    <cap-button [label]="'qube.project.button.select' | translate"
                styleClass="primary"
                (onClick)="showProjectSelector()">
    </cap-button>
  </div>
</div>
