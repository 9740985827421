<app-header [menuItems]="menuItems"></app-header>

<div id="wrapper" [ngClass]="{ 'small-screen-view': isSmallScreenView }">
  <div class="page-wrapper">
    <router-outlet></router-outlet>

    <div *ngIf="loadingService.isLoading() | async" class="spinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</div>
