import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { from, Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { ProjectApiService } from '@features/client/dashboard/services/project-api.service';
import { ListProject } from '@features/client/dashboard/models/project.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UserApiService } from '@features/client/profile/services/user-api.service';

@Component({
  selector: 'app-multi-project-selector',
  templateUrl: './multi-project-selector.component.html',
  styleUrls: ['./multi-project-selector.component.scss'],
})
export class MultiProjectSelectorComponent implements OnInit {
  @ViewChild('overlayPanel') public overlayPanel: OverlayPanel;
  @Input() public projects: ListProject[];
  @Output() public onSubmit: EventEmitter<string[]> = new EventEmitter<string[]>();

  public currentlySelectedProjects: string[];
  public hasManagerPermission$: Observable<boolean>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly projectService: ProjectApiService,
    private readonly userService: UserApiService,
    private readonly ngxPermissionsService: NgxPermissionsService,
  ) {
  }

  public ngOnInit(): void {
    this.hasManagerPermission$ = from(this.ngxPermissionsService.hasPermission(['project-user.manage']));
  }

  public showPanel($event: any): void {
    this.resetSelection();
    this.overlayPanel.toggle($event);
  }

  public handleLinkProject(): void {
    this.onSubmit.emit(this.currentlySelectedProjects);
    this.resetSelection();
    this.overlayPanel.hide();
  }

  public resetSelection(): void {
    this.currentlySelectedProjects = [];
  }
}
