<form [formGroup]="form">
  <cap-dropdown #projectDropdown
                formControlName="project_id"
                optionValue="key"
                filterBy="filterLabel"
                [ngClass]="{'small-screen-view': isSmallScreenView}"
                [options]="projects"
                [autoDisplayFirst]="true"
                [sortAlphabetically]="false"
                [filter]="true"
                (change)="setProject($event?.value)">
    <ng-template let-item capTemplate="selectedItem">
      {{ item?.label | translate }}
    </ng-template>

    <ng-template let-item capTemplate="item">
      <div class="row">
        <div class="col-5">{{ item?.value ? item?.label : (item?.label | translate) }}</div>
        <div *ngIf="item?.company_name" class="col-7">
          {{ item?.company_name }}
        </div>
      </div>
    </ng-template>
  </cap-dropdown>
</form>

<cap-button id="projectSelector"
            class="d-none"
            [hidden]="true"
            (click)="openProjectSelector()">
</cap-button>
