import { Injectable } from '@angular/core';
import { GeneralApiService } from '@shared/services/general-api.service';
import { Observable } from 'rxjs';
import { TenantSettings } from '@core/models/tenant-settings.model';
import { ApiHttpService } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class SettingService extends GeneralApiService<any> {
  protected endpoint: string = 'setting';

  constructor(public apiHttpService: ApiHttpService) {
    super(apiHttpService);
  }

  public getSettingsByTenantKey(tenantKey: string): Observable<TenantSettings> {
    return this.apiHttp.get(`/${this.endpoint}/${tenantKey}`);
  }
}
