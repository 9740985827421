import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss'],
})
export class PdfViewerDialogComponent implements OnInit {
  public publicUrl: string;
  public fileName: string;

  constructor(
    private readonly dialogReference: DynamicDialogRef,
    private readonly dialogConfig: DynamicDialogConfig,
  ) {
  }

  public ngOnInit(): void {
    this.publicUrl = this.dialogConfig?.data?.fileSource;
    this.fileName = this.dialogConfig?.data?.fileName;
  }

  public download(): void {
    saveAs(this.publicUrl, this.fileName);
  }

  public closeDialog(): void {
    this.dialogReference.close();
  }
}
