<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col">
        <div class="dialog-footer">
          <cap-button class="btn"
                      styleClass="secondary"
                      [label]="rejectionTranslationKey | translate"
                      (click)="closeDialog()">
          </cap-button>

          <cap-button class="btn"
                      styleClass="primary"
                      [label]="confirmationTranslationKey | translate"
                      (click)="submit()">
          </cap-button>
        </div>
      </div>
    </div>
  </div>
</div>
