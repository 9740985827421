import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { ValidatorService } from '@capturum/ui/api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormFacade {
  private formGroup: FormGroup;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly validatorService: ValidatorService,
  ) {
  }

  public destroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public setFormGroup(formGroup: FormGroup): void {
    this.formGroup = formGroup;
  }

  public getFormGroup(): FormGroup {
    return this.formGroup;
  }

  public markAsTouched(form?: any): void {
    if (!form) {
      form = this.formGroup;
    }

    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  public checkValidity(): boolean {
    if (this.formGroup.invalid) {
      this.markAsTouched(this.formGroup);
    }

    return this.formGroup.valid;
  }

  public extractFillData(target: any): object {
    Object.keys(target).forEach((key) => (target[key] == null) && delete target[key]);

    return target;
  }

  public fetchBackendErrors(): void {
    this.validatorService?.backendValidation$
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((errors: Record<string, string[]>) => {
        this.validatorService.setBackendErrorsOnForm(this.getFormGroup(), errors);
      });
  }

  public formsAreEqual(lastValue: any, currentValue: any): boolean {
    return JSON.stringify(lastValue) === JSON.stringify(currentValue);
  }
}
