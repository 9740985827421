import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { User } from '@features/client/project/models/user.model';

@Component({
  selector: 'app-multi-user-selector',
  templateUrl: './multi-user-selector.component.html',
  styleUrls: ['./multi-user-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiUserSelectorComponent {
  @ViewChild('usersOverlayPanel') public overlayPanel: OverlayPanel;
  @Input() public users: User[];
  @Output() public onSubmit: EventEmitter<string[]> = new EventEmitter<string[]>();

  public currentlySelectedUsers: string[];

  public showPanel($event: any): void {
    this.resetSelection();
    this.overlayPanel.toggle($event);
  }

  public handleLinkUser(): void {
    this.onSubmit.emit(this.currentlySelectedUsers);
    this.resetSelection();
    this.overlayPanel.hide();
  }

  public resetSelection(): void {
    this.currentlySelectedUsers = [];
  }
}
