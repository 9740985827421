import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyApiService } from '@features/client/dashboard/services/company-api.service';
import { MapItem } from '@capturum/ui/api';
import { forkJoin, Observable, of } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectApiService } from '@features/client/dashboard/services/project-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateFormats } from '@core/configs/app-date.config';
import { FormFacade } from '@core/services/form-facade.service';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';
import { BaseKeys } from '@core/enums/base-key.enum';
import { BaseDataService } from '@core/services/base-data.service';
import { UpdateCurrentProjectSelector } from '@shared/store/general.actions';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TenantSelectors } from '@core/store/tenant.selectors';
import { UserApiService } from '@features/client/profile/services/user-api.service';
import { ListOptions } from '@capturum/api/lib/interfaces/api.interface';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
  providers: [FormFacade],
})
export class ProjectFormComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public projectManagerLabel: string;
  public DateFormats: typeof DateFormats = DateFormats;
  public companies$: Observable<MapItem[]>;
  public projectTypeOptions: Observable<BaseDataValueMapItem[]>;
  public projectMethodOptions: Observable<BaseDataValueMapItem[]>;
  public persons$: Observable<{ contacts: MapItem[], managers: MapItem[] }>;

  constructor(
    private readonly dialogReference: DynamicDialogRef,
    private readonly formBuilder: FormBuilder,
    private readonly formFacade: FormFacade,
    private readonly projectApiService: ProjectApiService,
    private readonly companyApiService: CompanyApiService,
    private readonly userApiService: UserApiService,
    private readonly translateService: TranslateService,
    private readonly baseDataService: BaseDataService,
    private readonly messageService: MessageService,
    private readonly store: Store,
  ) {
  }

  public ngOnInit(): void {
    this.initializeForm();
    this.setProjectManagerLabel();

    this.projectMethodOptions = this.baseDataService.list(BaseKeys.projectMethod);
    this.projectTypeOptions = this.baseDataService.list(BaseKeys.projectType);
    this.companies$ = this.companyApiService.getItemsAsListOptions();

    this.formFacade.setFormGroup(this.form);
    this.setPersons();
    this.formFacade.fetchBackendErrors();
  }

  public ngOnDestroy(): void {
    this.formFacade.destroy();
  }

  public createProject(): void {
    if (!this.formFacade.checkValidity()) {
      this.formFacade.markAsTouched();

      return;
    }

    this.projectApiService.create(this.formFacade.extractFillData(this.formFacade.getFormGroup().value))
      .subscribe((result) => {
        this.dialogReference.close(result);
        this.store.dispatch(new UpdateCurrentProjectSelector());
      });
  }

  public closeDialog(): void {
    this.dialogReference.close();
  }

  private initializeForm(): void {
    this.form = this.formBuilder.group({
      company_id: [null, Validators.required],
      code: [null, [Validators.required, Validators.maxLength(20)]],
      name: [null, Validators.required],
      type_base_data_value_id: [null, Validators.required],
      method_base_data_value_id: [null, Validators.required],
      start_at: [null, Validators.required],
      deadline_at: [null, Validators.required],
      hours_sold: [null],
      projectManager: this.formBuilder.group({
        id: [{ value: null, disabled: true }, Validators.required],
      }),
      contact: this.formBuilder.group({
        id: [{ value: null, disabled: true }, Validators.required],
      }),
    });
  }

  private setProjectManagerLabel(): void {
    const settings = this.store.selectSnapshot(TenantSelectors.getTenantSettings);

    this.projectManagerLabel = settings['general.tenant_name'];
  }

  private setPersons(): void {
    const controlIds = [
      this.form.controls.projectManager.get('id'),
      this.form.controls.contact.get('id'),
    ];

    this.persons$ = this.form.controls.company_id.valueChanges.pipe(
      startWith(null),
      tap(() => controlIds.forEach(control => control.reset())),
      switchMap(companyId => {
        if (companyId) {
          controlIds.forEach(control => control.enable());

          const options: ListOptions = {
            filters: [
              {
                field: 'company.id',
                value: companyId,
              },
            ],
          };

          return forkJoin([
            this.userApiService.listProjectManagers(options),
            this.userApiService.listContactPersons(options),
          ]);
        }

        controlIds.forEach(control => control.disable());

        return of([[], []]);
      }),
      map(([managers, contacts]) => ({ managers, contacts })),
    );
  }
}
