enum GeneralRoutes {
  dashboard = 'dashboard',
  project = 'project',
  documents = 'documents',
  planning = 'planning',
  contracts = 'contracts',
  user = 'user',
}

enum GeneralClientRoutes {
  client = '/client',
  clientDashboard = '/client/dashboard',
  clientProjects = '/client/projects',
  clientProject = '/client/project',
  clientDocuments = '/client/documents',
  clientPlanning = '/client/planning',
  clientContracts = '/client/contracts',
  adminUser = '/admin/user',
}

enum UserRoutes {
  profile = '/client/profile',
  setting = '/client/setting',
}

enum AdminRoutes {
  config = '/admin/config',
}

enum AuthRoutes {
  authLogin = '/auth/login',
  authForgot = '/auth/forgot-password',
  twoFactorLogin = '/auth/two-factor',
}

enum CommonRoutes {
  detail = 'detail',
  edit = 'detail/:id',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...GeneralRoutes,
  ...AuthRoutes,
  ...UserRoutes,
  ...CommonRoutes,
  ...AdminRoutes,
};

export type ClientRoutes = typeof ClientRoutes;
export const ClientRoutes = {
  ...GeneralClientRoutes,
};

export const ProjectDependentRoutes = {
  clientProject: GeneralClientRoutes.clientProject,
  clientDocuments: GeneralClientRoutes.clientDocuments,
  clientContracts: GeneralClientRoutes.clientContracts,
  adminUser: GeneralClientRoutes.adminUser,
  clientPlanning: GeneralClientRoutes.clientPlanning,
};
