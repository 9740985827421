import { Pipe, PipeTransform } from '@angular/core';
import { DocumentType } from '@core/enums/document-type.enum';

@Pipe({
  name: 'documentTypeIcon',
})
export class DocumentTypeIconPipe implements PipeTransform {
  public transform(documentType: DocumentType): string {
    switch (documentType) {
      case DocumentType.report:
        return `far fa-file-alt fa-lg`;
      case DocumentType.quotation:
        return `far fa-file-spreadsheet fa-lg`;
      case DocumentType.manual:
        return `far fa-file-code fa-lg`;
      case DocumentType.invoice:
        return `far fa-file-invoice fa-lg`;
      case DocumentType.contract:
        return `far fa-file-contract fa-lg`;
      default:
        return 'far fa-file fa-lg';
    }
  }
}
