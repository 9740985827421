import { ApiListResult } from '@capturum/api';
import { MapItem } from '@capturum/ui/api';
import { Observable } from 'rxjs';

// @TODO: Import these from Cap/AUTH when they are added to public.ts export file
export function apiListResultToMapItem(result: ApiListResult, valueProperty: string = 'value', labelProperty: string = 'label'): MapItem[] {
  return result.data.map((item) => {
    return {
      label: item[labelProperty],
      value: item[valueProperty],
    };
  });
}

export function toMapItems(source: Observable<ApiListResult>, valueProperty: string = 'key', labelProperty: string = 'label'): Observable<MapItem[]> {
  return new Observable(subscriber => {
    source.subscribe({
      next(value: ApiListResult): void {
        if (value !== undefined && value !== null) {
          subscriber.next(apiListResultToMapItem(value, valueProperty, labelProperty));
        }
      },
      error(error: any): void {
        subscriber.error(error);
      },
      complete(): void {
        subscriber.complete();
      },
    });
  });
}

export function responseData(source: Observable<{ data: any }>): Observable<any> {
  return new Observable(subscriber => {
    source.subscribe({
      next(value: ApiListResult): void {
        if (value) {
          subscriber.next(value.data);
        }

        subscriber.complete();
      },
    });
  });
}
