<div *ngIf="hasManagerPermission$ | async"
     class="multi-project-selector-wrapper">
  <cap-button [icon]="'fas fa-link'"
              [label]="'qube.user.detail.assigned_projects.button.link_project' | translate"
              styleClass="tertiary edit-btn"
              (click)="showPanel($event)">
  </cap-button>

  <p-overlayPanel #overlayPanel
                  [dismissable]="true"
                  appendTo="body"
                  styleClass="cap-overlay-panel primary">
    <ng-template pTemplate>
      <div class="multi-project-listbox">
        <p-listbox [options]="projects"
                   [filterPlaceHolder]="'qube.general.dropdown.select.placeholder' | translate : { subject: 'qube.planning.popup.project.label' | translate | lowercase }"
                   [(ngModel)]="currentlySelectedProjects"
                   [checkbox]="true"
                   [filter]="true"
                   [multiple]="true"
                   [metaKeySelection]="false"
                   [showToggleAll]="false"
                   optionLabel="filterLabel"
                   optionValue="key">
          <ng-template let-item pTemplate="item">
            <div class="multi-project-item">
              <div class="project-name">{{  item.company_name }}</div>

              <div class="company-name">{{ item.label }}</div>
            </div>
          </ng-template>
        </p-listbox>

        <cap-button [label]="'qube.user.detail.assigned_projects.button.link' | translate"
                    styleClass="primary m-3"
                    (click)="handleLinkProject()">
        </cap-button>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
