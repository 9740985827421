import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApiModule } from '@capturum/api';
import { AuthModule } from '@capturum/auth';
import { CompleteModule, PermissionGuard, TranslationLoader } from '@capturum/complete';
import { LoginModule } from '@capturum/login';
import { SharedModule } from '@capturum/shared';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumToastModule } from '@capturum/ui/toast';
import { API_DATE_BLACKLIST, API_DATE_PROPERTIES, DateFormats } from '@core/configs/app-date.config';
import { CoreModule } from '@core/core.module';
import { getErrorHandler } from '@core/errorHandlers/sentry.errorHandler';
import { ProjectInterceptor } from '@core/interceptors/project.interceptor';
import { TenantState } from '@core/store/tenant.state';
import { environment } from '@environments/environment';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { GeneralState } from '@shared/store/general.state';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService } from 'primeng/api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

Sentry.init({
  dsn: environment.sentryUrl,
  environment: environment.name,
});

export function onAuthError(): void {
  window.location.href = `auth/login?redirectUrl=${window.location.pathname}`;
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    return `${params.key}`;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([GeneralState, TenantState]),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    NgxPermissionsModule.forRoot(),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: 'auth/login',
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
      dateInterceptor: {
        dateProperties: API_DATE_PROPERTIES,
        blackListedURLs: API_DATE_BLACKLIST,
        blackListedStatusCodes: [401, 204],
        displayFormat: DateFormats.displayWithTimeFormatDateFns,
        sendFormat: DateFormats.sendFormatDateFns,
      },
    }),
    LoginModule.forRoot({
      environment,
      productName: 'complete-frontend',
      redirectAfterLogin: '',
    }),
    SharedModule.forRoot(),
    CompleteModule.forRoot({
      indexedDbModels: [],
      databaseName: 'qube.complete',
      version: 1,
      environment: environment,
    }, NgxPermissionsService, NgxPermissionsModule),
    CoreModule,
    CapturumButtonModule,
    CapturumToastModule,
  ],
  providers: [
    ConfirmationService,
    PermissionGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProjectInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
