<div class="filters">
  <div *ngIf="showLabel" class="filter-label">
    {{ 'table.filters' | translate }}
  </div>

  <div *ngIf="activeFilters"
       class="filter-items-row">
    <div *ngFor="let filter of filters"
         [ngClass]="filter.wrapperStyleClass"
         class="filter-item">
      <ng-container [ngSwitch]="filter.type">
        <ng-container *ngSwitchCase="FilterType.INPUT">
          <cap-input [ngModel]="filter.isSearch ? activeFilters?.global?.value : activeFilters[filter.field]?.value"
                     [placeholder]="filter.placeholder | observablePipe | async"
                     class="has-icon"
                     styleClass="cap-rounded"
                     (onInput)="setFilter($event.target.value, filter.field, filter.matchMode || FilterMatchMode.LIKE, filter.isSearch)">
          </cap-input>
        </ng-container>

        <ng-container *ngSwitchCase="FilterType.MULTISELECT">
          <ng-container *ngIf="filter.options | observablePipe | async as options">
            <cap-multi-select [ngModel]="activeFilters[filter.field]?.value"
                              [defaultLabel]="filter.placeholder | observablePipe | async"
                              [options]="options"
                              class="has-icon"
                              styleClass="cap-rounded"
                              (change)="setFilter($event.value, filter.field, FilterMatchMode.IN)">
            </cap-multi-select>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="FilterType.SELECT">
          <ng-container *ngIf="filter.options | observablePipe | async as options">
            <cap-dropdown [ngModel]="activeFilters[filter.field]?.value"
                          [autoDisplayFirst]="false"
                          [showClear]="true"
                          [placeholder]="filter.placeholder | observablePipe | async"
                          [options]="options"
                          [sortAlphabetically]="true"
                          styleClass="cap-rounded"
                          class="has-icon"
                          (change)="setFilter($event.value, filter.field, FilterMatchMode.EQUALS)">
            </cap-dropdown>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="FilterType.DATEPICKER">
          <cap-calendar [ngModel]="activeFilters[filter.field]?.value"
                        [readonlyInput]="true"
                        [placeholder]="filter.placeholder"
                        styleClass="cap-rounded w-100"
                        (select)="setFilter($event, filter.field, filter.matchMode || FilterMatchMode.EQUALS)">
          </cap-calendar>
        </ng-container>

        <ng-container *ngTemplateOutlet="filterIcon; context:{icon: filter.icon}">
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #filterIcon let-icon="icon">
  <div class="filter-icon">
    <i [ngClass]="icon"></i>
  </div>
</ng-template>
