import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ValidatorService } from '@capturum/ui/api';
import { ChangeProject } from '@src/app/shared/store/general.actions';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class HttpCodeInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly validatorService: ValidatorService,
    private readonly translateService: TranslateService,
    private readonly store: Store,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 400:
              case 403:
              case 405:
              case 422:
                this.parseValidations(error.error);

                break;
              case 401:
                this.parseValidations(error.error);
                this.router.navigate([AppRoutes.authLogin]);

                break;
              case 404:
                if (error.error.message === 'exception.user_not_assigned_to_project' || error.error.message === this.translateService.instant('qube.exception.user_not_assigned_to_project')) {
                  this.store.dispatch(new ChangeProject(null));

                  this.router.navigate([AppRoutes.dashboard]);
                }

                break;
              default:

                break;
            }
          }
        },
      ),
    );
  }

  private parseValidations(response: { errors: Record<string, string[]>, message: string }): void {
    const keys = response.errors && Object.keys(response.errors);
    const nonField = !response.errors;

    if (keys && keys.length) {
      const fieldErrors = keys.reduce((accumulator, key) =>
        ({ ...accumulator, [key]: response.errors[key] }), {},
      );

      this.validatorService.backendValidation.next(fieldErrors);
    }

    if (nonField) {
      const message = response.message;

      this.toastService.error(this.translateService.instant('toast.error.title'), message);
    }
  }
}
