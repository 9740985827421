import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SettingService, VersionCheckService } from '@capturum/complete';
import { ThemeService } from '@capturum/ui/api';
import { InfoTableConfigService } from '@capturum/ui/info-table';
import { ProjectDependentRoutes } from '@core/enums/routes.enum';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ChangeProject } from '@shared/store/general.actions';
import { GeneralSelectors } from '@shared/store/general.selectors';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <cap-toast position="top-right"></cap-toast>
  `,
  providers: [VersionCheckService]
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly versionCheckService: VersionCheckService,
    private readonly themeService: ThemeService,
    private readonly settingService: SettingService,
    private readonly store: Store,
    private readonly actions: Actions,
    private readonly infoTableConfigService: InfoTableConfigService,
    private readonly translateService: TranslateService
  ) {
    this.settingService.setGlobalStyles();

    this.versionCheckService.initVersionCheck(`${environment.url}/version.json`, 10000);

    this.versionCheckService.onUpdateVersion.subscribe(() => {
      window.location.reload();
    });
  }

  public ngOnInit(): void {
    this.observeProjectRoutesChanges();
    this.observeProjectStateChanges();
    this.setInfoTableConfig();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private observeProjectRoutesChanges(): void {
    this.subscription.add(
      this.router.events.subscribe(route => {
        if (route instanceof NavigationEnd) {
          const routeProjectId = this.getRouteProjectId(route.url);

          if (!routeProjectId) {
            // If there's a current project set in the project selector,
            // append the projectId parameter to the current route.
            this.navigateToProjectRoute(
              this.store.selectSnapshot(GeneralSelectors.getProjectId),
              route.url,
            );
          }
        }
      }),
    );
  }

  private observeProjectStateChanges(): void {
    this.subscription.add(
      this.actions.pipe(ofActionSuccessful(ChangeProject))
        .subscribe((project) => {
            // If the current project gets changed in the project selector,
            // replace the projectId parameter from the current route.
            if (this.getRouteProjectId(this.router.url) !== project.projectId) {
              this.navigateToProjectRoute(project?.project?.id, this.router.url);
            }
          },
        ),
    );
  }

  private getRouteProjectId(route: string): string {
    if (this.getCurrentProjectDependentRoutePath(route)) {
      const routeParts = route.split('/');
      // Get the UUID/projectId route parameter which is always in the 3rd segment.
      const uuidPart = routeParts.length > 2 ? routeParts[3] : null;

      if (uuidPart) {
        // Match the UUID string.
        const regexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        const matches = regexp.exec(uuidPart);

        return matches ? matches[0] : null;
      }
    }

    return null;
  }

  private getCurrentProjectDependentRoutePath(route: string): string {
    const routeParts = route.split('/');
    const currentProjectDependentRoute = routeParts.slice(0, 3).join('/');

    for (const projectDependentRoute in ProjectDependentRoutes) {
      if (ProjectDependentRoutes.hasOwnProperty(projectDependentRoute)) {
        if (ProjectDependentRoutes[projectDependentRoute] === currentProjectDependentRoute) {
          return currentProjectDependentRoute;
        }
      }
    }

    return null;
  }

  private navigateToProjectRoute(projectId: string, route: string): void {
    const projectRoutePath = this.getCurrentProjectDependentRoutePath(route);

    if (projectRoutePath) {
      const routeParts = route.split('/');
      const afterProjectIdPath = routeParts.slice(4).join('/');

      const redirectTo = [projectRoutePath];

      redirectTo.push(projectId || 'all');


      if (afterProjectIdPath) {
        redirectTo.push(afterProjectIdPath);
      }

      this.router.navigate(redirectTo);
    }
  }

  private setInfoTableConfig(): void {
    this.infoTableConfigService.getConfig().pipe(first()).subscribe((config) => {
      this.infoTableConfigService.setConfig({
        defaultTexts: {
          ...config.defaultTexts,
          noResults: this.translateService.stream('table.no_results'),
        },
      });
    });
  }
}
