<header class="header"
        [ngClass]="{'small-screen-view': isSmallScreenView}">
  <div class="header__logo">
    <a>
      <img [routerLink]="ClientRoutes.clientDashboard"
           [src]="logoSource"
           [alt]="'general.tenant_name' | setting : ''" />
    </a>

    <ng-container *ngIf="!isMobileView">
      <ng-container *ngTemplateOutlet="projectSelector"></ng-container>
    </ng-container>

    <div class="mobile-actions" *ngIf="isSmallScreenView">
      <cap-action-menu [openPosition]="'left-bottom'" [actionItems]="actionMenuItems">
      </cap-action-menu>
    </div>
  </div>

  <div class="header__actions" *ngIf="!isSmallScreenView">
    <cap-button *ngxPermissionsOnly="['project.manage', 'project.manage.tenant']"
                styleClass="create-project"
                icon="fas fa-plus"
                label="{{ 'qube.general.create_new.entity' | translate : { entity: 'qube.header.project.title' | translate | lowercase } }}"
                (click)="showProjectCreationDialog()">
    </cap-button>

    <cap-button *ngxPermissionsOnly="['setting.manage']"
                [routerLink]="AppRoutes.config"
                routerLinkActive
                #rlaSetting="routerLinkActive"
                styleClass="{{ rlaSetting.isActive ? 'active' : '' }}"
                icon="fas fa-cog">
    </cap-button>

    <cap-button [routerLink]="AppRoutes.profile"
                routerLinkActive
                #rlaProfile="routerLinkActive"
                styleClass="{{ rlaProfile.isActive ? 'active' : '' }}"
                icon="fas fa-user"
                title="{{ 'link.profile' | translate }}">
    </cap-button>

    <cap-button styleClass="icon-btn-primary mr-2"
                icon="fas fa-sign-out"
                title="{{ 'link.logout' | translate }}"
                (click)="logout()">
    </cap-button>
  </div>

  <ng-container *ngIf="isMobileView">
    <ng-container *ngTemplateOutlet="projectSelector"></ng-container>
  </ng-container>
</header>

<div class="app-menu" [ngClass]="{'small-screen-view': isSmallScreenView}">
  <ng-container *ngFor="let menuItem of menuItems">
    <ng-container *ngxPermissionsOnly="menuItem.permissions">
      <div class="app-menu__separator"></div>

      <div class="app-menu__item"
           [routerLink]="menuItem.routerLink"
           routerLinkActive="active">

        <div class="app-menu__item_heading">
          <i class="fas fa-{{ menuItem.icon }}"></i>

          <div class="app-menu__item_heading_title">
            {{ menuItem.title | translate }}
            <span *ngIf="menuItem.unopenedCount" class="unopened-count">{{ menuItem.unopenedCount }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="app-menu__separator"></div>
</div>

<ng-template #projectSelector>
  <div class="project-selector">
    <div class="header-brand">
      {{ tenantName }}

      <span *ngIf="companyName && (companyName !== 'qube.header.show_all_projects.placeholder')"
            class="company-name">
            <span *ngIf="tenantName">| </span>

            <span>{{ companyName }}</span>
      </span>
    </div>

    <app-current-project-selector (setCompanyName)="setCompanyName($event)"></app-current-project-selector>
  </div>
</ng-template>
