<form [formGroup]="form">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col">
          <cap-dropdown formControlName="company_id"
                        [autoDisplayFirst]="false"
                        [placeholder]="('qube.general.dropdown.select.placeholder' | translate : { subject: 'qube.profile.company.name.label' | translate | lowercase}) | capitalize"
                        [options]="companies$ | async"
                        [sortAlphabetically]="true"
                        [filter]="true"
                        [label]="'qube.profile.company.name.label' | translate">
          </cap-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <cap-input formControlName="code"
                     maxLength="20"
                     [label]="'qube.project.information.project_number.label' | translate">
          </cap-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <cap-input formControlName="name"
                     [label]="'qube.project.information.project_name.label' | translate">
          </cap-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <cap-dropdown formControlName="type_base_data_value_id"
                        appendTo="body"
                        [autoDisplayFirst]="false"
                        [placeholder]="('qube.general.dropdown.select.placeholder' | translate : { subject: 'qube.project.information.type.label' | translate | lowercase }) | capitalize"
                        [label]="'qube.project.information.type.label' | translate"
                        [options]=" projectTypeOptions | async">
          </cap-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <cap-dropdown formControlName="method_base_data_value_id"
                        appendTo="body"
                        [autoDisplayFirst]="false"
                        [placeholder]="('qube.general.dropdown.select.placeholder' | translate : { subject: 'qube.project.information.method.label' | translate | lowercase }) | capitalize"
                        [label]="'qube.project.information.method.label' | translate"
                        [options]=" projectMethodOptions | async">
          </cap-dropdown>
        </div>
      </div>
    </div>


    <div class="col-6">
      <div class="row">
        <div class="col">
          <div class="date-picker">
            <cap-calendar formControlName="start_at"
                          [label]="'qube.project.popup.period.label' | translate"
                          [placeholder]="DateFormats.displayShortFormat | uppercase">
            </cap-calendar>

            <i class="far fa-arrow-right icon"></i>

            <cap-calendar formControlName="deadline_at"
                          label="*"
                          [placeholder]="DateFormats.displayShortFormat | uppercase"
                          class="last-calendar"
                          appendTo="body">
            </cap-calendar>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <cap-input *ngxPermissionsOnly="['project.manage', 'project.manage.tenant']"
                     formControlName="hours_sold"
                     filter="pint"
                     [label]="'qube.project.information.hours_sold.label' | translate">
          </cap-input>
        </div>
      </div>

      <ng-container *ngIf="persons$ | async as persons">
        <div class="row">
          <div class="col" formGroupName="projectManager">
            <cap-dropdown formControlName="id"
                          [placeholder]="('qube.general.dropdown.select.placeholder' | translate : { subject: 'qube.general.tenant_pm.entity' | translate :  { entity: projectManagerLabel } }) | capitalize"
                          [options]="persons?.managers"
                          [label]="'qube.general.tenant_pm.entity' | translate :  { entity: projectManagerLabel }">
            </cap-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col" formGroupName="contact">
            <cap-dropdown formControlName="id"
                          appendTo="body"
                          [options]="persons?.contacts"
                          [placeholder]="('qube.general.dropdown.select.placeholder' | translate : { subject: 'qube.project.information.contact_person.label' | translate | lowercase }) | capitalize"
                          [label]="'qube.project.information.contact_person.label' | translate">
            </cap-dropdown>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col">
          <div class="dialog-footer">
            <cap-button class="btn"
                        styleClass="secondary"
                        [label]="'button.cancel' | translate"
                        (click)="closeDialog()">
            </cap-button>

            <cap-button class="btn"
                        type="submit"
                        styleClass="primary"
                        [label]="'button.add' | translate"
                        (click)="createProject()">
            </cap-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
