import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {
  public transform(value: string | Date, args?: any): Date {
    return value ? moment(value).toDate() : null;
  }
}
