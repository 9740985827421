import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@capturum/auth';
import {
  BaseDataResolver,
  FilteredTranslationResolver,
  IndexedDbGuard,
  PermissionGuard,
  SettingResolver,
  TranslationResolver
} from '@capturum/complete';
import { TenantResolver } from '@src/app/shared/resolvers/tenant.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'client',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: FilteredTranslationResolver,
      tenant: TenantResolver
    }
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard, PermissionGuard],
    resolve: {
      baseData: BaseDataResolver,
      translations: TranslationResolver,
      settings: SettingResolver,
      tenant: TenantResolver,
    },

    children: [
      {
        path: 'client',
        loadChildren: () => import('./features/client/client.module').then((m) => m.ClientModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'client'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
