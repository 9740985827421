import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appButtonLoading]',
})
export class ButtonLoadingDirective implements AfterViewInit {
  @Input() set submitting(value: boolean) {
    this.isSubmitted = value;

    this.prepareLoading();
  }

  get submitting(): boolean {
    return this.isSubmitted;
  }

  @Input() public loadingClass: string = 'cap-button--loading';
  @Input() public label: string;

  private isSubmitted: boolean;
  private buttonElement: HTMLButtonElement;

  constructor(
    private readonly elementReference: ElementRef,
    private readonly translateService: TranslateService,
  ) {
  }

  public ngAfterViewInit(): void {
    this.buttonElement = (this.elementReference.nativeElement.getElementsByClassName('p-button') as HTMLCollection)
      .item(0) as HTMLButtonElement;
  }

  private prepareLoading(): void {
    if (this.buttonElement) {
      const method = this.isSubmitted ? 'add' : 'remove';
      const label = this.isSubmitted ? this.translateService.instant('component.loading') : this.label;

      this.buttonElement.classList[method](this.loadingClass);

      const span = (this.buttonElement.getElementsByClassName('p-button-label') as HTMLCollection)
        .item(0) as HTMLSpanElement;
      span.innerText = label;
    }
  }
}
