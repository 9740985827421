import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { Observable } from 'rxjs';
import { MapItem, ToastService } from '@capturum/ui/api';
import { ListOptions } from '@capturum/api/lib/interfaces/api.interface';
import { UserRoles } from '@features/client/profile/enums/user-role.enum';
import { GeneralApiService } from '@shared/services/general-api.service';
import { User } from '../../project/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends GeneralApiService<User> {
  protected endpoint: string = 'user';

  constructor(
    private toastService: ToastService,
    private api: ApiHttpService,
  ) {
    super(api);
  }

  public listProjectManagers(options?: ListOptions): Observable<MapItem[]> {
    return super.getItemsAsListOptions(
      this.appendRoleOption(UserRoles.projectManagerQube, options),
    );
  }

  public listAccountManagers(options?: ListOptions): Observable<MapItem[]> {
    return super.getItemsAsListOptions(
      this.appendRoleOption(UserRoles.accountManager, options),
    );
  }

  public listContactPersons(options?: ListOptions): Observable<MapItem[]> {
    return super.getItemsAsListOptions(
      this.appendRoleOption(UserRoles.projectManagerClient, options),
    );
  }

  private appendRoleOption(role: string, options?: ListOptions): ListOptions {
    return {
      ...options,
      filters: [...(options?.filters || []), {
        field: 'roles.key',
        value: role,
      }],
    };
  }
}
