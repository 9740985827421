import { Injectable } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ConverterUtilService {
  public static listToOption(items: { key: string, label: string }[]): MapItem[] {
    return items.map((item: any) => {
      return { label: item.label, value: item.key };
    });
  }

  public static arrayToSelectItems<T>(items: T[]): SelectItem[] {
    return items.map((value: T) => ({ label: value.toString(), value }));
  }

  public static customObjectToMapItem<T>(response: T[], label: string[] = ['name'], value: string = 'id', delimiter: string = ' '): MapItem[] {
    return response?.map(item => ({
      label: label.reduce((acc, labelField) => acc += `${(item[labelField] ? item[labelField] + delimiter : '')}`, '').slice(0, -delimiter.length),
      value: item[value],
    }));
  }
}
