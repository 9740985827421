import { Pipe, PipeTransform } from '@angular/core';
import { AssetTypes } from '@core/enums/asset-type.enum';

@Pipe({
  name: 'assets',
})
export class AssetsPipe implements PipeTransform {
  public transform(value: string, type: string = AssetTypes.images): string {
    switch (type) {
      case AssetTypes.images:
        return `./assets/images/${value}`;
      case AssetTypes.flags:
        return `./assets/flags/${value}`;
      case AssetTypes.svg:
        return `./assets/svg/${value}`;
      default:
        return `./assets/${value}`;
    }
  }
}
