import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectFormComponent } from '@core/components/project-form/project-form.component';
import { AppRoutes, ClientRoutes } from '@core/enums/routes.enum';
import { ActionMenuItem } from '@core/models/action-menu-item.model';
import { MenuItem } from '@core/models/menu-item.model';
import { ResponsiveScreenService } from '@core/services/responsive-screen.service';
import { TenantSelectors } from '@core/store/tenant.selectors';
import { Project } from '@features/client/dashboard/models/project.model';
import { ProjectApiService } from '@features/client/dashboard/services/project-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ChangeProject, SetUser, UpdateCurrentProjectSelector } from '@shared/store/general.actions';
import { GeneralSelectors } from '@shared/store/general.selectors';
import { NgxPermissionsService } from 'ngx-permissions';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthService as CapturumAuthService } from '@capturum/auth';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public menuItems: MenuItem[];

  public AppRoutes: typeof AppRoutes = AppRoutes;
  public ClientRoutes: typeof ClientRoutes = ClientRoutes;
  public companyName: string;
  public logoSource: string;
  public tenantName: string;
  public headerBackground: string;
  public isMobileView: boolean;
  public isSmallScreenView: boolean;
  public actionMenuItems: ActionMenuItem[];

  private subscription: Subscription = new Subscription();

  constructor(
    private readonly dialogService: DialogService,
    private readonly authService: CapturumAuthService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly projectApiService: ProjectApiService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly responsiveScreenService: ResponsiveScreenService,
    private readonly store: Store,
    private readonly actions: Actions,
  ) {
  }

  public ngOnInit(): void {
    this.setCompanyName();
    this.setSettings();
    this.getFilteredActionMenuItems();

    this.subscription.add(
      this.responsiveScreenService.mobileScreenView$.subscribe((isMobileScreenView) => {
        this.isMobileView = isMobileScreenView;
      }),
    );

    this.subscription.add(
      this.responsiveScreenService.smallScreenView$.subscribe((isSmallScreenView) => {
        this.isSmallScreenView = isSmallScreenView;
      }),
    );

    this.subscription.add(
      this.actions.pipe(ofActionSuccessful(ChangeProject)).subscribe(() => this.setCompanyName()),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public showProjectCreationDialog(): void {
    const dialogReference = this.dialogService.open(ProjectFormComponent, {
      header: this.translateService.instant(
        `qube.general.create_new.entity`,
        {
          entity: this.translateService.instant('qube.header.project.title').toLowerCase(),
        },
      ),
    });

    dialogReference.onClose.pipe(first()).subscribe((project: Project) => {
      if (project) {
        this.store.dispatch([
          new ChangeProject({ id: project.id, companyId: project.company_id }),
          new UpdateCurrentProjectSelector(),
        ]);

        this.router.navigate([ClientRoutes.clientProject, project.id]);
      }
    });
  }

  /**
   * Logout and redirect to login screen
   */
  public logout(): void {
    this.authService.logout().subscribe((result) => {
      if (result) {
        this.store.dispatch(new SetUser(null));

        this.router.navigate(['/auth/login']);
      }
    });
  }

  public setCompanyName(companyName?: string): void {
    if (companyName) {
      this.companyName = companyName;
    } else {
      const projectId = this.store.selectSnapshot(GeneralSelectors.getProjectId);

      if (projectId) {
        this.projectApiService.get(projectId, { include: ['company'] }).subscribe(project =>
          this.companyName = project.company.name,
        );
      } else {
        this.companyName = null;
      }
    }
  }

  private setSettings(): void {
    const settings = this.store.selectSnapshot(TenantSelectors.getTenantSettings);

    if (settings) {
      this.headerBackground = settings['qube.tenant_header_theme'];
      this.logoSource = settings['qube.tenant_header_logo'];

      if (settings['qube.show_company_name_in_project_selector']) {
        this.tenantName = settings['general.tenant_name'];
      }
    }
  }

  private getFilteredActionMenuItems(): void {
    this.ngxPermissionsService.permissions$.pipe(
      first(),
      map((permissionObject) => {
        const permissions = Object.keys(permissionObject);
        const filteredMenuItems = this.getMenuItems().filter((item) => item.requiredPermission ? permissions.includes(item.requiredPermission) : true);

        return filteredMenuItems;
      }),
    ).subscribe((items) => {
      this.actionMenuItems = items;
    });
  }

  private getMenuItems(): ActionMenuItem[] {
    return [
      {
        label: this.translateService.instant('qube.general.create_new.entity', { entity: this.translateService.instant('qube.header.project.title').toLowerCase() }),
        icon: 'fas fa-plus',
        callback: () => {
          this.showProjectCreationDialog();
        },
        requiredPermission: 'project.manage',
      },
      {
        label: this.translateService.instant('config.manage.title'),
        icon: 'fas fa-cog',
        callback: () => {
          this.router.navigateByUrl(AppRoutes.config);
        },
        requiredPermission: 'setting.manage',
      },
      {
        label: this.translateService.instant('link.profile'),
        icon: 'fas fa-user',
        callback: () => {
          this.router.navigateByUrl(AppRoutes.profile);
        },
        requiredPermission: 'setting.manage',
      },
      {
        label: this.translateService.instant('link.logout'),
        icon: 'fas fa-sign-out',
        callback: () => {
          this.logout();
        },
      },
    ];
  }
}
