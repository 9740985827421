import { Action, State, StateContext } from '@ngxs/store';
import { GeneralStatePrototype } from './general-state-prototype.model';
import { Injectable } from '@angular/core';
import {
  ChangeProject,
  ResetLogoUploader,
  SetFileName,
  SetSubmittingStatus,
  SetUnopenedCounters, SetUser,
} from './general.actions';

@State<GeneralStatePrototype>({
  name: 'general',
})
@Injectable()
export class GeneralState {
  @Action(ChangeProject)
  public changeProject(ctx: StateContext<GeneralStatePrototype>, { project }: ChangeProject): void {
    const state = ctx.getState();

    ctx.setState({ ...state, project });
  }

  @Action(SetUnopenedCounters)
  public setUnopenedCounters(ctx: StateContext<GeneralStatePrototype>, { unopenedCounter }: SetUnopenedCounters): void {
    const state = ctx.getState();

    ctx.setState({ ...state, unopenedCounter });
  }

  @Action(SetUser)
  public setUser(ctx: StateContext<GeneralStatePrototype>, { user }: SetUser): void {
    const state = ctx.getState();

    ctx.setState({ ...state, user });
  }

  @Action(SetFileName)
  public setFileName(ctx: StateContext<GeneralStatePrototype>, { key, fileName }: SetFileName): void {
    const state = ctx.getState();
    const file = state?.file ?? {};
    file[key] = fileName;

    ctx.patchState({ file });
  }

  @Action(ResetLogoUploader)
  public resetLogoUploader(ctx: StateContext<GeneralStatePrototype>, { projectLogoType }: ResetLogoUploader): void {
    const state = ctx.getState();

    ctx.setState({ ...state, projectLogoType });
  }

  @Action(SetSubmittingStatus)
  public setSubmitting(ctx: StateContext<GeneralStatePrototype>, { key, status }: SetSubmittingStatus): void {
    const state = ctx.getState();
    const submitting = state?.submitting ?? {};
    submitting[key] = status;

    ctx.patchState({ submitting });
  }
}
