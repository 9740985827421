import { Selector } from '@ngxs/store';
import { GeneralState } from './general.state';
import { GeneralStatePrototype } from './general-state-prototype.model';
import { User } from '@features/client/project/models/user.model';

export class GeneralSelectors {
  @Selector([GeneralState])
  public static getProjectId(state: GeneralStatePrototype): string {
    return state.project?.id;
  }

  @Selector([GeneralState])
  public static getProject(state: GeneralStatePrototype): { id: string, companyId: string } {
    return state.project;
  }

  @Selector([GeneralState])
  public static getUnopenedDocumentCount(state: GeneralStatePrototype): number {
    return state.unopenedCounter;
  }

  @Selector([GeneralState])
  public static getFileName(state: GeneralStatePrototype): Record<string, string> {
    return state.file;
  }

  @Selector([GeneralState])
  public static getSubmitting(state: GeneralStatePrototype): Record<string, boolean> {
    return state.submitting;
  }

  @Selector([GeneralState])
  public static getUser(state: GeneralStatePrototype): User {
    return state.user;
  }
}
