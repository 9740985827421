export enum DetailsTileDataType {
  string = 'string',
  date = 'date',
  status = 'status',
  base = 'base',
}

export enum LinkableType {
  phone = 'tel:',
  email = 'mailto:',
}
