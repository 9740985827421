import { Injectable } from '@angular/core';
import { Company } from '@features/client/dashboard/models/company.model';
import { GeneralApiService } from '@shared/services/general-api.service';
import { ApiHttpService } from '@capturum/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService extends GeneralApiService<Company> {
  protected endpoint: string = 'company';

  constructor(public apiHttpService: ApiHttpService) {
    super(apiHttpService);
  }

  public updateCompany(companyId: string, data: any): Observable<Company> {
    const url = `/${this.endpoint}/${companyId}/details`;

    return this.apiHttp.patch(url, data);
  }
}
