<div *ngxPermissionsOnly="['project.manage', 'project.manage.tenant']"
     class="multi-user-selector-wrapper">
  <cap-button styleClass="tertiary edit-btn"
              [icon]="'fas fa-link'"
              [label]="'qube.project.detail.assigned_users.button.link_user' | translate"
              (click)="showPanel($event)">
  </cap-button>

  <p-overlayPanel #usersOverlayPanel
                  appendTo="body"
                  styleClass="cap-overlay-panel primary"
                  [dismissable]="true">
    <ng-template pTemplate>
      <div class="multi-user-listbox">
        <p-listbox optionLabel="full_name"
                   optionValue="key"
                   [(ngModel)]="currentlySelectedUsers"
                   [checkbox]="true"
                   [filterPlaceHolder]="'qube.general.dropdown.select.placeholder' | translate : { subject: 'qube.project.popup.user.label' | translate | lowercase }"
                   [filter]="true"
                   [metaKeySelection]="false"
                   [multiple]="true"
                   [options]="users"
                   [showToggleAll]="false">
          <ng-template let-item pTemplate="item">
            <div class="multi-user-item">
              <div class="user-name">{{  item?.full_name }}</div>
              <div class="user-role">{{  item?.currentRole?.name }}</div>
            </div>
          </ng-template>
        </p-listbox>

        <cap-button styleClass="primary m-3"
                    [label]="'qube.project.detail.assigned_users.button.link' | translate"
                    (click)="handleLinkUser()">
        </cap-button>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
