import { Injectable } from '@angular/core';
import { TenantKeys } from '@shared/enums/tenant-keys.enum';

export interface IconsConfig {
  [name: string]: {
    type: string;
    href: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  private _elementId: string = 'tenant-specific-favicon';
  private _icons: IconsConfig = {
    [TenantKeys.qube]: {
      type: 'image/x-icon',
      href: `./assets/icons/favicon-${TenantKeys.qube}.ico`,
    },
    [TenantKeys.capturum]: {
      type: 'image/png',
      href: `./assets/icons/favicon-${TenantKeys.capturum}.png`,
    },
    [TenantKeys.emendis]: {
      type: 'image/png',
      href: `./assets/icons/favicon-${TenantKeys.emendis}.png`,
    },
    [TenantKeys.adamas]: {
      type: 'image/png',
      href: `./assets/icons/favicon-${TenantKeys.adamas}.ico`,
    },
  };

  public setIcon(name: string): void {
    this.setNode(this._icons[name]?.type, this._icons[name]?.href);
  }

  public reset(): void {
    this.removeNode();
  }

  private addNode(type: string, href: string): void {
    const element = document.createElement('link');

    element.setAttribute('id', this._elementId);
    element.setAttribute('rel', 'icon');
    element.setAttribute('type', type);
    element.setAttribute('href', href);

    document.head.appendChild(element);
  }

  private removeNode(): void {
    const element = document.head.querySelector(`#${this._elementId}`);

    if (element) {
      document.head.removeChild(element);
    }
  }

  private setNode(type: string, href: string): void {
    this.removeNode();
    this.addNode(type, href);
  }
}
