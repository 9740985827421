import { TenantSettings } from '@core/models/tenant-settings.model';

export class SetTenantKey {
  public static readonly type = '[TENANT] Set tenant key';

  constructor(public key: string) {
  }
}

export class SetTenantSettings {
  public static readonly type = '[TENANT] Set tenant settings';

  constructor(public settings: TenantSettings) {
  }
}

export class SetTenantSetting {
  public static readonly type = '[TENANT] Set tenant setting';

  constructor(
    public setting: { [key: string]: any; }
  ) {
  }
}
