import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumPdfViewerModule } from '@capturum/ui/pdf-viewer';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { QubeBaseListComponent } from '@shared/base/qube-base-list.component';
import { FormAlertComponent } from '@shared/components/form-alert/form-alert.component';
import { HyperlinkComponent } from '@shared/components/hyperlink/hyperlink.component';
import { InputCodeComponent } from '@shared/components/input-code/input-code.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { ButtonLoadingDirective } from '@shared/directives/button-loading.directive';
import { AssetsPipe } from '@shared/pipes/assets.pipe';
import { ContractStatusIconPipe } from '@shared/pipes/contract-status-icon.pipe';
import { DocumentTypeIconPipe } from '@shared/pipes/document-type-icon.pipe';
import { ToDatePipe } from '@shared/pipes/to-date.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BackToOverviewLinkComponent } from './components/back-to-overview-link/back-to-overview-link.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import {
  CurrentProjectSelectorComponent,
} from './components/current-project-selector/current-project-selector.component';
import { DetailsTileComponent } from './components/details-tile/details-tile.component';
import { MultiProjectSelectorComponent } from './components/multi-project-selector/multi-project-selector.component';
import { NavigationCardComponent } from './components/navigation-card/navigation-card.component';
import { NoProjectComponent } from './components/no-project/no-project.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { BaseDataTranslationPipe } from './pipes/base-data-translation.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MultiUserSelectorComponent } from '@shared/components/multi-user-selector/multi-user-selector.component';

@NgModule({
  declarations: [
    AssetsPipe,
    BaseDataTranslationPipe,
    SafeHtmlPipe,
    ToDatePipe,
    DocumentTypeIconPipe,
    ContractStatusIconPipe,
    FormAlertComponent,
    NavigationCardComponent,
    CurrentProjectSelectorComponent,
    TableFilterComponent,
    LoaderComponent,
    NoProjectComponent,
    DetailsTileComponent,
    InputCodeComponent,
    MultiProjectSelectorComponent,
    ConfirmationDialogComponent,
    QubeBaseListComponent,
    PdfViewerDialogComponent,
    HyperlinkComponent,
    CapitalizePipe,
    BackToOverviewLinkComponent,
    ButtonLoadingDirective,
    MultiUserSelectorComponent,
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    CapturumButtonModule,
    TranslateModule,
    CapturumSharedModule,
    FormlyModule,
    RouterModule,
    ReactiveFormsModule,
    CapturumDropdownModule,
    CapturumInputModule,
    FormsModule,
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumCheckboxModule,
    CapturumPdfViewerModule,
    DynamicDialogModule,
    OverlayPanelModule,
    ListboxModule,
  ],
  exports: [
    AssetsPipe,
    BaseDataTranslationPipe,
    SafeHtmlPipe,
    ToDatePipe,
    DocumentTypeIconPipe,
    ContractStatusIconPipe,
    FormAlertComponent,
    NavigationCardComponent,
    CurrentProjectSelectorComponent,
    TableFilterComponent,
    LoaderComponent,
    NoProjectComponent,
    DetailsTileComponent,
    InputCodeComponent,
    MultiProjectSelectorComponent,
    BackToOverviewLinkComponent,
    NgxPermissionsModule,
    TranslateModule,
    CapturumSharedModule,
    HyperlinkComponent,
    CapitalizePipe,
    ButtonLoadingDirective,
    MultiUserSelectorComponent,
  ],
  providers: [
    BaseDataTranslationPipe,
    DynamicDialogConfig,
  ],
})
export class SharedModule {
}
