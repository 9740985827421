import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, fromEventPattern, Observable } from 'rxjs';
import { MOBILE_VIEW_WIDTH, SMALL_SCREEN_VIEW_WIDTH } from '@core/constants/screen-width.constant';
import { DestroyBase } from '@capturum/shared';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveScreenService extends DestroyBase {
  public innerWidth: number;

  public mobileScreenViewWidth = MOBILE_VIEW_WIDTH;
  public smallScreenViewWidth = SMALL_SCREEN_VIEW_WIDTH;

  public mobileScreenView: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public mobileScreenView$: Observable<boolean> = this.mobileScreenView.asObservable().pipe(takeUntil(this.destroy$));

  public smallScreenView: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public smallScreenView$: Observable<boolean> = this.smallScreenView.asObservable().pipe(takeUntil(this.destroy$));

  private onResize$: Observable<Event>;

  constructor(
    private readonly rendererFactory2: RendererFactory2,
  ) {
    super();

    this.innerWidth = window.innerWidth;

    this.mobileScreenView.next(this.innerWidth < this.mobileScreenViewWidth);
    this.smallScreenView.next(this.innerWidth < this.smallScreenViewWidth);

    const renderer = this.rendererFactory2.createRenderer(null, null);

    this.createResizeObservable(renderer);
    this.checkScreenWidth();
  }

  public checkScreenWidth(): void {
    this.onResize$.subscribe(() => {
      this.innerWidth = window.innerWidth;

      if (this.mobileScreenView.getValue() !== (this.innerWidth < this.mobileScreenViewWidth)) {
        this.mobileScreenView.next(this.innerWidth < this.mobileScreenViewWidth);
      }

      if (this.smallScreenView.getValue() !== (this.innerWidth < this.smallScreenViewWidth)) {
        this.smallScreenView.next(this.innerWidth < this.smallScreenViewWidth);
      }
    });
  }

  private createResizeObservable(renderer: Renderer2): void {
    let removeClickEventListener: () => void;
    const createClickEventListener = (
      handler: (e: Event) => boolean | void
    ) => {
      removeClickEventListener = renderer.listen('window', 'resize', handler);
    };

    this.onResize$ = fromEventPattern<Event>(createClickEventListener, () =>
      removeClickEventListener()
    ).pipe(takeUntil(this.destroy$));
  }
}
