import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProjectApiService } from '@features/client/dashboard/services/project-api.service';
import { TranslateService } from '@ngx-translate/core';
import { CapturumDropdownComponent } from '@capturum/ui/dropdown';
import { NgxPermissionsService } from 'ngx-permissions';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { ChangeProject, UpdateCurrentProjectSelector } from '@shared/store/general.actions';
import { GeneralSelectors } from '@shared/store/general.selectors';
import { first, tap } from 'rxjs/operators';
import { ListProject } from '@features/client/dashboard/models/project.model';
import { ResponsiveScreenService } from '@core/services/responsive-screen.service';

@Component({
  selector: 'app-current-project-selector',
  templateUrl: './current-project-selector.component.html',
  styleUrls: ['./current-project-selector.component.scss'],
})
export class CurrentProjectSelectorComponent implements OnInit, OnDestroy {
  @Output() public setCompanyName = new EventEmitter<string>();
  @ViewChild('projectDropdown') public projectSelector: CapturumDropdownComponent;
  @Select(GeneralSelectors.getProjectId) public projectId$: Observable<string>;

  public form: FormGroup;
  public projects: ListProject[] = [];
  public hasPermission$: Observable<boolean>;
  public isSmallScreenView: boolean;

  private subscription: Subscription = new Subscription();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly projectApiService: ProjectApiService,
    private readonly translateService: TranslateService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly responsiveScreenService: ResponsiveScreenService,
    private readonly store: Store,
    private readonly actions: Actions,
  ) {
  }

  public ngOnInit(): void {
    this.setForm();

    this.subscription.add(
      this.responsiveScreenService.smallScreenView$.subscribe((isSmallScreenView) => {
        this.isSmallScreenView = isSmallScreenView;
      }),
    );

    this.hasPermission$ = from(this.ngxPermissionsService.hasPermission(['project.manage', 'project.manage.tenant'])).pipe(
      first(),
      tap((hasPermission: boolean) => {
        this.setOptions(hasPermission);
      }),
    );

    this.subscription.add(
      combineLatest([
        this.actions.pipe(ofActionSuccessful(UpdateCurrentProjectSelector)),
        this.hasPermission$,
      ]).subscribe(([action, hasPermission]) => {
        this.setOptions(hasPermission);
      }),
    );

    this.subscription.add(
      this.projectId$.subscribe(() => this.autoSelectOption()),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public openProjectSelector(): void {
    setTimeout(() => {
      this.projectSelector.dropdown.show();
      this.projectSelector.dropdown.accessibleViewChild.nativeElement.click();
    }, 0);
  }

  public setProject(projectId: string): void {
    const project = this.projects.find(item => item.key === projectId);

    if (project?.key) {
      this.store.dispatch(new ChangeProject({ id: project.key, companyId: project.company_id }));
      this.setCompanyName.emit(project.company_name);
    } else {
      this.store.dispatch(new ChangeProject(null));
      this.setCompanyName.emit(project?.label);
    }
  }

  private setForm(): void {
    this.form = this.formBuilder.group({
      project_id: [null],
    });
  }

  private setOptions(hasPermission: boolean): void {
    this.projectApiService.getProjectList().subscribe(projectList => {
      const projects: ListProject[] = projectList.data;

      this.projects = [
        {
          company_name: null,
          key: null,
          label: 'qube.header.show_all_projects.placeholder',
          filterLabel: null,
          company_id: null,
        },
        ...projects.map((project: ListProject) => ({
          company_id: project.company_id,
          company_name: project.company_name,
          key: project.key,
          label: project.label,
          filterLabel: (hasPermission) ? `${project.label} ${project.company_name}` : project.label,
        }))];

      this.autoSelectOption();
    });
  }

  private autoSelectOption(): void {
    const projectId = this.store.selectSnapshot(GeneralSelectors.getProjectId);

    if (this.projects?.length === 1) {
      this.setProject(projectId);
    }

    this.setOption(projectId);
  }

  private setOption(projectId: string): void {
    const currentlySelectedProjectId = this.form.controls.project_id.value;

    if (currentlySelectedProjectId !== projectId) {
      this.form.controls.project_id.setValue(projectId);
    }
  }
}
