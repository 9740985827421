import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { MenuModule } from 'primeng/menu';
import { CompleteModule, ErrorMessageInterceptor } from '@capturum/complete';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumActionMenuModule } from '@capturum/ui/action-menu';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { ProjectApiService } from '@features/client/dashboard/services/project-api.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCodeInterceptor } from '@core/interceptors/code.interceptor';
import { QubeErrorMessageInterceptor } from '@core/interceptors/error-message.interceptor';

@NgModule({
  declarations: [
    BasicLayoutComponent,
    HeaderComponent,
    ProjectFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenuModule,
    CompleteModule,
    ConfirmDialogModule,
    SharedModule,
    BrowserAnimationsModule,
    CapturumButtonModule,
    CapturumActionMenuModule,
    TranslateModule,
    ProgressSpinnerModule,
    CapturumInputModule,
    CapturumDropdownModule,
    ReactiveFormsModule,
    CapturumCalendarModule,
  ],
  providers: [
    ProjectApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCodeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QubeErrorMessageInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
}
