export enum BaseKeys {
  projectType = 'project_type',
  projectMethod = 'project_method',
  iterationStatus = 'iteration_status',
  projectStatus = 'project_status',
  meetingType = 'meeting_type',
  invoiceStatus = 'invoice_status',
  fileType = 'file_type',
  contractType = 'contract_rule_type',
  contractStatus = 'contract_status',
  country = 'country_code',
}
