import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GeneralSelectors } from '@shared/store/general.selectors';

@Injectable()
export class ProjectInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const projectId = this.store.selectSnapshot(GeneralSelectors.getProjectId);

    if ((projectId) && (!request.url.includes('last-iteration'))) {
      request = request.clone({
        setHeaders: {
          'api-project-id': projectId,
        },
      });
    }

    return next.handle(request);
  }
}
