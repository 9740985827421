import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-project',
  templateUrl: './no-project.component.html',
  styleUrls: ['./no-project.component.scss'],
})
export class NoProjectComponent {
  @Input() public description: string = 'qube.project.error.message';

  public showProjectSelector(): void {
    document.getElementById('projectSelector').click();
  }
}
