export const API_DATE_PROPERTIES = [
  'created_at',
  'updated_at',
  'deleted_at',
];

export const API_DATE_BLACKLIST = [
  '/api/role/translation',
  '/api/role/module',
  '/api/base-data-key',
  '/api/role/setting',
  '/api/role/base-data',
  '/api/role/permission',
];

export enum DateFormats {
  displayTimeFormat = 'HH:mm',
  displayFormat = 'dd-MM-yyyy',
  displayShortFormat = 'dd-MM-yy',
  displayWithTimeFormat = 'DD-MM-YYYY HH:mm:ss',
  displayWithTimeFormatDateFns = 'dd-MM-yyyy HH:mm:ss',
  sendFormat = 'YYYY-MM-DD',
  sendFormatDateFns = 'yyyy-MM-dd',
  messageFormat = 'MMMM Do',
  notificationFormat = 'MMMM DD [at] hh:mm',
  fullFormat = 'EEEE MMMM d y',
}
