import { FilterMatchMode } from '@capturum/ui/api';
import { CapturumInfoTableComponent, InfoTableColumn } from '@capturum/ui/info-table';
import { Component, Injector, OnDestroy, ViewChild } from '@angular/core';
import { BaseListComponent } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { FilterMetadata, SelectItem } from 'primeng/api';
import { FilterConfig } from '../components/table-filter/table-filter.component';
import { ConverterUtilService } from '@core/services/converter-util.service';
import { Subject } from 'rxjs';

@Component({ template: '' })
export class QubeBaseListComponent<T> extends BaseListComponent<T> implements OnDestroy {
  @ViewChild(CapturumInfoTableComponent, { static: false }) public infoTable: CapturumInfoTableComponent;

  public perPageOptions: SelectItem[] = ConverterUtilService.arrayToSelectItems([10, 15, 30, 60]);
  public perPage: number = this.perPageOptions[0].value;
  public tableColumns: InfoTableColumn[];
  public filterConfig: FilterConfig[];
  public removeDataWrappers = false;

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public injector: Injector,
  ) {
    super(injector, injector.get(TranslateService));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public filterGlobal(value: string): void {
    this.infoTable.primeNGTable.filterGlobal(value, 'contains');
  }

  public filter(event: { value: any, field: string, matchMode: string }): void {
    this.infoTable.filterTable(event.value, event.field, event.matchMode as FilterMatchMode);
  }

  public get activeFilters(): { [key: string]: FilterMetadata|FilterMetadata[] } {
    let activeFilters = {};

    if (this.infoTable && this.infoTable.activeFilters) {
      activeFilters = this.infoTable.activeFilters;

      for (const key in activeFilters) {
        if (activeFilters.hasOwnProperty(key)) {
          const value = (activeFilters[key] as FilterMetadata).value;

          if (Date.parse(value) && !(value instanceof Date)) {
            activeFilters[key] = { ...activeFilters[key], value: new Date(value) };
          }
        }
      }
    }

    return activeFilters;
  }
}
