import { Selector } from '@ngxs/store';
import { TenantState } from '@core/store/tenant.state';
import { TenantStatePrototype } from '@core/store/tenant-state-prototype.model';
import { TenantSettings } from '@core/models/tenant-settings.model';

export class TenantSelectors {
  @Selector([TenantState])
  public static getTenantKey(state: TenantStatePrototype): string {
    return state.key;
  }

  @Selector([TenantState])
  public static getTenantSettings(state: TenantStatePrototype): TenantSettings {
    return state.settings;
  }
}
