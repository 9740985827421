import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { map } from 'rxjs/operators';
import { ConverterUtilService } from '@core/services/converter-util.service';
import { ListOptions } from '@capturum/api/lib/interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class GeneralApiService<Model> extends ApiService<Model> {
  constructor(public apiHttpService: ApiHttpService) {
    super(apiHttpService);
  }

  public getItemsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    return this.list(options).pipe(
      map(response => ConverterUtilService.listToOption(response.data)),
    );
  }
}
