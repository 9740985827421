import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterMatchMode, FilterType, MapItem } from '@capturum/ui/api';
import { FilterMetadata } from 'primeng/api';
import { Observable } from 'rxjs';

export interface FilterConfig {
  field: string;
  type: FilterType;
  label?: string;
  placeholder?: string | Observable<string>;
  icon?: string;
  options?: MapItem[] | Observable<MapItem[]>;
  wrapperStyleClass?: string;
  isSearch?: boolean;
  dataTestAttribute?: string;
  matchMode?: FilterMatchMode;
}

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent {
  @Input() public showLabel: boolean = true;
  @Input() public filters: FilterConfig[];
  @Input() public activeFilters: Record<string, FilterMetadata>;
  @Output() public onFilter = new EventEmitter<{ field: string, value: any, matchMode: string }>();
  @Output() public onSearch = new EventEmitter<string>();

  public FilterType: typeof FilterType = FilterType;
  public FilterMatchMode: typeof FilterMatchMode = FilterMatchMode;

  public setFilter(value: any, field: string, matchMode: string, isSearch?: boolean): void {
    if (isSearch) {
      this.onSearch.emit(value);
    } else {
      this.onFilter.emit({ field, value, matchMode });
    }
  }
}
