import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {
  public confirmationTranslationKey: string;
  public rejectionTranslationKey: string;

  constructor(
    private readonly dialogReference: DynamicDialogRef,
    private readonly dialogConfig: DynamicDialogConfig,
  ) {
  }

  public ngOnInit(): void {
    this.rejectionTranslationKey = this.dialogConfig.data?.rejectionTranslationKey || 'button.cancel';
    this.confirmationTranslationKey = this.dialogConfig.data?.confirmationTranslationKey || 'button.delete';
  }

  public submit(): void {
    this.dialogReference.close(true);
  }

  public closeDialog(): void {
    this.dialogReference.close(false);
  }
}
