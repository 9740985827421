import { User } from '@features/client/project/models/user.model';

export class ChangeProject {
  public static readonly type = '[GENERAL] Change project';

  constructor(public project: { id: string, companyId: string }) {
  }
}

export class SetUnopenedCounters {
  public static readonly type = '[GENERAL] Set unopened count';

  constructor(public unopenedCounter: number) {
  }
}

export class UpdateCurrentProjectSelector {
  public static readonly type = '[GENERAL] UpdateCurrentProjectSelector';
}

export class SetSubmittingStatus {
  public static readonly type = '[GENERAL] Set submitting status';

  constructor(public key: string, public status: boolean) {
  }
}

export class SetFileName {
  public static readonly type = '[GENERAL] Set file name';

  constructor(public key: string, public fileName: string) {
  }
}

export class ResetLogoUploader {
  public static readonly type = '[GENERAL] Reset file uploader';

  constructor(public projectLogoType: string) {
  }
}

export class SetUser {
  public static readonly type = '[GENERAL] Set user';

  constructor(public user: User) {
  }
}
