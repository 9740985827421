import { HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { ErrorMessageInterceptor } from '@capturum/complete';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QubeErrorMessageInterceptor extends ErrorMessageInterceptor implements HttpInterceptor {
  public defaultParsingErrors(error: HttpErrorResponse): void {
    if (error.error?.message === 'exception.user_not_assigned_to_project') {
      error.error.message = this.translateService.instant('qube.exception.user_not_assigned_to_project');
    }

    super.defaultParsingErrors(error);
  }
}
