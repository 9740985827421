import { Injectable } from '@angular/core';
import { GeneralApiService } from '@shared/services/general-api.service';
import { Document } from '@features/client/document/models/document.model';
import { Observable } from 'rxjs';
import { ApiHttpService, ApiIndexResult, ApiSingleResult, ListOptions } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService extends GeneralApiService<Document> {
  protected endpoint: string = 'document';

  constructor(public apiHttpService: ApiHttpService) {
    super(apiHttpService);
  }

  public getUnopenedDocuments(options: ListOptions): Observable<ApiIndexResult<Document>> {
    let url = `/${this.endpoint}/unopened`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getInvoices(options: ListOptions): Observable<ApiIndexResult<Document>> {
    let url = `/${this.endpoint}/invoice`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getFavoriteDocuments(options: ListOptions): Observable<ApiIndexResult<Document>> {
    let url = `/${this.endpoint}/favorite`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getLatestDocumentsByType(options: ListOptions, type?: string): Observable<ApiIndexResult<Document>> {
    let url = `/${this.endpoint}`;

    if (type) {
      url += `/${type}`;
    }

    url += `/latest`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public toggleIsFavorite(documentId: string): Observable<ApiSingleResult<Document>> {
    const url = `/${this.endpoint}/${documentId}/toggle-favorite`;

    return this.apiHttp.put(url, null);
  }

  public toggleIsOpened(documentId: string): Observable<ApiSingleResult<Document>> {
    const url = `/${this.endpoint}/${documentId}/touch`;

    return this.apiHttp.put(url, null);
  }
}
