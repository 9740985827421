import { Component, Input } from '@angular/core';
import { LinkableType } from '@core/enums/details-tile-data.enum';

@Component({
  selector: 'app-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss'],
})
export class HyperlinkComponent {
  @Input() public type: LinkableType;
  @Input() public uri: string;

  public schemeByType: { [key: string]: string } = {
    [LinkableType.phone]: 'tel:',
    [LinkableType.email]: 'mailto:',
  };
}
